import React from "react"
import { Banner } from "@shopify/polaris"
import markdownParser from "../../../helpers/markdownParser"
export default function BannerContentPreview({ content }) {
  let fontSize = "1.4rem"
  switch (content.size) {
    case "extraSmall":
      fontSize = "1rem"
      break
    case "small":
      fontSize = "1.2rem"
      break
    case "base":
      fontSize = "1.4rem"
      break
    case "medium":
      fontSize = "1.6rem"
      break
    case "large":
      fontSize = "1.9rem"
      break
    case "extraLarge":
      fontSize = "2.4rem"
      break
  }
  const paragraphMarkup = (items, index) => {
    return (
      <p>
        {items.map((item, iIndex) => {
          return item.link ? (
            <a
              url={item.link}
              key={iIndex}
              style={{ color: "#1773b0", textDecoration: "underline" }}
            >
              {item.text}
            </a>
          ) : (
            item.text.map((text, tIndex) => {
              return (
                <span key={tIndex} style={{ paddingRight: "3px" }}>
                  {text}
                </span>
              )
            })
          )
        })}
      </p>
    )
  }
  return (
    <div>
      <Banner status={content.color} title={content.bannerTitle}>
        <div
          style={{
            display: "grid",
            justifyItems: content.alignment,
            fontSize: "62.5%",
          }}
        >
          <div
            style={{
              fontSize: fontSize,
              fontStyle: "italic" === content.emphasis ? "italic" : "normal",
              fontWeight: "bold" === content.emphasis ? "bold" : "normal",
            }}
          >
            {content.bannerText &&
              markdownParser(content.bannerText).map((items, index) => {
                return items.list ? (
                  <ul key={index}>
                    {items.list.map((listItem, listIndex) => {
                      return (
                        <li key={listIndex}>
                          {paragraphMarkup(listItem, listIndex)}
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  paragraphMarkup(items, index)
                )
              })}
          </div>
        </div>
      </Banner>
    </div>
  )
}
