export default(markdownText) => {
    const lines = markdownText.split('\n');
    const result = [];
    let currentParagraph = [];
    let currentList = null;

    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;

    function splitText(text) {
        const words = text.split(/(\s+|(?<!\d)[\.,!?;:](?!\d))/).filter(word => word.trim().length > 0);
        const mergedWords = [];
        for (let i = 0; i < words.length; i++) {
            if (words[i].match(/^[\.,!?;:]$/) && i > 0) {
                mergedWords[mergedWords.length - 1] += words[i];
            } else {
                mergedWords.push(words[i]);
            }
        }
        return mergedWords;
    }

    lines.forEach(line => {
        if (line.startsWith('- ')) {
            if (!currentList) {
                if (currentParagraph.length > 0) {
                    result.push(currentParagraph);
                    currentParagraph = [];
                }
                currentList = [];
            }

            let listItem = line.substring(2).trim();
            const listItemParts = [];

            while (listItem.length > 0) {
                const linkMatch = linkRegex.exec(listItem);

                if (linkMatch) {
                    if (linkMatch.index > 0) {
                        const textParts = splitText(listItem.substring(0, linkMatch.index));
                        listItemParts.push({ text: textParts });
                    }

                    listItemParts.push({
                        text: linkMatch[1],
                        link: linkMatch[2]
                    });
                    listItem = listItem.substring(linkMatch.index + linkMatch[0].length);
                } else {
                    const textParts = splitText(listItem);
                    listItemParts.push({ text: textParts });
                    listItem = '';
                }
            }

            currentList.push(listItemParts);
        } else if (line.trim() === '') {
            if (currentParagraph.length > 0) {
                result.push(currentParagraph);
                currentParagraph = [];
            }

            if (currentList) {
                result.push({ list: currentList });
                currentList = null;
            }
        } else {
            if (currentList) {
                result.push({ list: currentList });
                currentList = null;
            }

            let paragraphText = line.trim();
            const paragraphParts = [];

            while (paragraphText.length > 0) {
                const linkMatch = linkRegex.exec(paragraphText);

                if (linkMatch) {
                    if (linkMatch.index > 0) {
                        const textParts = splitText(paragraphText.substring(0, linkMatch.index));
                        paragraphParts.push({ text: textParts });
                    }

                    paragraphParts.push({
                        text: linkMatch[1],
                        link: linkMatch[2]
                    });
                    paragraphText = paragraphText.substring(linkMatch.index + linkMatch[0].length);
                } else {
                    const textParts = splitText(paragraphText);
                    paragraphParts.push({ text: textParts });
                    paragraphText = '';
                }
            }

            currentParagraph.push(...paragraphParts);
            result.push(currentParagraph);
            currentParagraph = [];
        }
    });

    if (currentParagraph.length > 0) {
        result.push(currentParagraph);
    }

    if (currentList) {
        result.push({ list: currentList });
    }

    return result;
}